import React, { useState, useEffect } from "react";
// import "./AddPackage.css";

// components imports
import Loader from "../../../../../Components/CircularLoader/CircularLoader";
// apis imports
import { editPallet } from "../../../../../services/product.service";
import { getUserWarehousesByWar_Id } from "../../../../../services/warehouse.services";
// library imports
import Backdrop from "@mui/material/Backdrop";
import Modal from "@mui/material/Modal";
import { useSelector } from "react-redux";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import FormLabel from "@mui/material/FormLabel";
import LocationDropdown from "./LocationDropdown";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";

const initialData = {
  name: "",
  length: "",
  width: "",
  height: "",
  weight: "",
  unit: "imperial",
  client: "",
};

export default function EditPallet({ open, setOpen, setSnack, setRefresh }) {
  const [data, setData] = useState(initialData);
  const [location, setLocation] = useState();
  const [loading, setLoading] = useState(false);
  const [clients, setClients] = useState([]);
  const user = useSelector((state) => state.user);
  const handleClose = () => {
    setData(initialData);
    setLocation();
    setLoading(false);
    setClients([]);
    setOpen(false);
  };
  const handleChange = (e) => {
    const { name, value } = e.target;
    setData({ ...data, [name]: value });
  };
  const handleUnitChange = (e) => {
    setData({ ...data, unit: e.target.value });
  };

  const getAllWarehouseClients = async () => {
    const response = await getUserWarehousesByWar_Id(
      user?.warehouse_id,
      1,
      50,
      ""
    );
    if (!response.error) {
      setClients(response?.data?.result?.data);
    }
  };

  useEffect(() => {
    if (open) {
      getAllWarehouseClients();
      setData({
        name: open?.name ?? "",
        length: open?.length ?? "",
        width: open?.width ?? "",
        height: open?.height ?? "",
        weight: open?.weight ?? "",
        unit: open?.unit ?? "",
        client: parseInt(open?.shipper_id) ?? "",
      });
      if (open?.location_id) {
        setLocation(open?.location);
      }
    }
  }, [open]);

  const handleUpdate = async () => {
    setLoading(true);
    for (const key in data) {
      if (data[key] === "") {
        setSnack((snack) => {
          return {
            ...snack,
            open: true,
            text: `${key.toUpperCase()} is required!`,
            severity: "error",
          };
        });
        setLoading(false);
        return;
      }
    }

    const payload = {
      name: data.name,
      length: data.length,
      width: data.width,
      height: data.height,
      weight: data.weight,
      unit: data.unit,
      location_id: location?.id ?? "",
      shipper_id: data.client,
    };
    const saved = await editPallet(payload, open?.id);
    setLoading(false);
    if (!saved.error) {
      setRefresh(Math.floor(Math.random() * 100) + 1);
      setSnack((snack) => {
        return {
          ...snack,
          open: true,
          text: "Pallet Updated successfully",
          severity: "success",
        };
      });
    } else {
      setSnack((snack) => {
        return {
          ...snack,
          open: true,
          text: "Something went wrong!",
          severity: "error",
        };
      });
    }

    handleClose();
  };

  return (
    <div>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={open}
        onClose={handleClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <div className="add__package_modal add__pallet_modal_wrapper">
          <div className="add__package_modalheading">Edit Pallet</div>
          <div className="add__package_modalcontent">
            <div className="add__package_modalrow">
              <div
                className="add__package_modalinputwrap"
                style={{ minWidth: "65%" }}
              >
                <input
                  className="add__package_modalinput"
                  placeholder="Pallet Name"
                  name="name"
                  value={data.name}
                  onChange={handleChange}
                />
              </div>
            </div>
            <div className="add__package_modalrow">
              <div className="add__package_modalinputwrap">
                <input
                  className="add__package_modalinput"
                  placeholder="Length"
                  name="length"
                  value={data.length}
                  onChange={handleChange}
                  type="number"
                />
              </div>
              <div className="add__package_modalinputwrap">
                <input
                  className="add__package_modalinput"
                  placeholder="Width"
                  name="width"
                  value={data.width}
                  onChange={handleChange}
                  type="number"
                />
              </div>
              <div className="add__package_modalinputwrap">
                <input
                  className="add__package_modalinput"
                  placeholder="Height"
                  name="height"
                  value={data.height}
                  onChange={handleChange}
                  type="number"
                />
              </div>
              <div className="add__package_modalinputwrap">
                <input
                  className="add__package_modalinput"
                  type="number"
                  value={data.weight}
                  name="weight"
                  placeholder="Weight"
                  onChange={handleChange}
                />
              </div>
            </div>

            <div className="add__package_modalrow">
              <div className="add__package_modalinputwrap">
                <FormControl
                  sx={{
                    flexDirection: "row",
                    alignItems: "center",
                    paddingLeft: "10px",
                  }}
                >
                  <FormLabel id="demo-controlled-radio-buttons-group">
                    Unit:
                  </FormLabel>
                  <RadioGroup
                    aria-labelledby="demo-controlled-radio-buttons-group"
                    name="controlled-radio-buttons-group"
                    value={data.unit}
                    onChange={handleUnitChange}
                    sx={{ flexDirection: "row", alignItems: "center" }}
                  >
                    <FormControlLabel
                      value="imperial"
                      control={<Radio />}
                      label="Imperial"
                      sx={{ marginLeft: "20px" }}
                    />
                    <FormControlLabel
                      value="metric"
                      control={<Radio />}
                      label="Metric"
                    />
                  </RadioGroup>
                </FormControl>
              </div>
            </div>
            <div className="add__package_modalrow">
              <div className="add__package_modalinputwrap  add__package_modalinputwrap_location">
                <LocationDropdown
                  setValue={setLocation}
                  value={location}
                  setSnack={setSnack}
                />
              </div>
              <div className="add__package_modalinputwrap add__package_modalinputwrap_client">
                <Select
                  value={data?.client}
                  onChange={(e) => handleChange(e)}
                  label="User"
                  name="client"
                  placeholder="Client"
                  style={{ width: "100%" }}
                  size="small"
                  disabled={true}
                  displayEmpty
                  renderValue={(selected) => {
                    if (selected === "") {
                      return <>Client</>;
                    }
                    const selectedClient = clients.find(
                      (client) => client?.shipper?.id === selected
                    );
                    return selectedClient?.shipper?.username?.toUpperCase();
                  }}
                >
                  <MenuItem disabled value="">
                    <em>Client</em>
                  </MenuItem>
                  {clients?.map((client) => (
                    <MenuItem key={client?.id} value={client?.shipper?.id}>
                      {client?.shipper?.username?.toUpperCase()}
                    </MenuItem>
                  ))}
                </Select>
              </div>
            </div>
          </div>
          <div className="add__package_modalfooter  add__package_modalinputwrap_modalfooter">
            <div className="add__package_modalcncl" onClick={handleClose}>
              Cancel
            </div>
            <div className="add__package_modalsave" onClick={handleUpdate}>
              {loading ? <Loader /> : "Update"}
            </div>
          </div>
        </div>
      </Modal>
    </div>
  );
}

import axios from "./axiosInstance";

export const fetchAllProducts = async (
  page,
  perPage,
  filter,
  warehouse = ""
) => {
  let response = { data: null, error: null };
  try {
    const res = await axios.get(
      `product${warehouse}?perPage=${perPage}&page=${page}&filterValue=${filter}`
    );
    response = { ...response, data: res.data };
  } catch (error) {
    response = { ...response, error: error };
  }
  return response;
};

export const fetchOnlyProducts = async (page, perPage, filter) => {
  let response = { data: null, error: null };
  try {
    const res = await axios.get(
      `product/get-all-products?perPage=${perPage}&page=${page}&filteredValue=${filter}`
    );
    response = { ...response, data: res.data };
  } catch (error) {
    response = { ...response, error: error };
  }
  return response;
};

export const fetchAllWarehouseProducts = async (
  page,
  perPage,
  filter,
  warehouse,
  Nested
) => {
  let response = { data: null, error: null };
  try {
    const res = await axios.get(
      `product/get-all-products/${warehouse}?perPage=${perPage}&page=${page}&filterValue=${filter}${Nested}`
    );
    response = { ...response, data: res.data };
  } catch (error) {
    response = { ...response, error: error };
  }
  return response;
};

export const fetchAllWarehousePallets = async (
  page,
  perPage,
  filter,
  Nested
) => {
  let response = { data: null, error: null };
  try {
    const res = await axios.get(
      `warehouse/product-location/pallet?perPage=${perPage}&page=${page}&filterValue=${filter}${Nested}`
    );
    response = { ...response, data: res.data };
  } catch (error) {
    response = { ...response, error: error };
  }
  return response;
};

export const createWarehousePallet = async (data) => {
  let response = { data: null, error: null };
  try {
    const res = await axios.post(`warehouse/product-location/pallet`, data);
    response = { ...response, data: res.data };
  } catch (error) {
    response = { ...response, error: error };
  }
  return response;
};

export const fetchAllAssignedPallets = async (id) => {
  let response = { data: null, error: null };
  try {
    const res = await axios.get(
      `warehouse/product-location/assign-product/get-by-product-warehouse-id/${id}`
    );
    response = { ...response, data: res.data };
  } catch (error) {
    response = { ...response, error: error };
  }
  return response;
};

export const fetchAllProductLot = async (id) => {
  let response = { data: null, error: null };
  try {
    const res = await axios.get(
      `warehouse/product-lot/get-by-product-id/${id}`
    );
    response = { ...response, data: res.data };
  } catch (error) {
    response = { ...response, error: error };
  }
  return response;
};

export const fetchAllProductPallets = async (id) => {
  let response = { data: null, error: null };
  try {
    const res = await axios.get(
      `warehouse/product-location/assign-product/get-by-pallet-id/${id}`
    );
    response = { ...response, data: res.data };
  } catch (error) {
    response = { ...response, error: error };
  }
  return response;
};

export const fetchAllShipperPallets = async (id) => {
  let response = { data: null, error: null };
  try {
    const res = await axios.get(
      `warehouse/product-location/pallet/get-by-shipper-id/${id}`
    );
    response = { ...response, data: res.data };
  } catch (error) {
    response = { ...response, error: error };
  }
  return response;
};

export const assignProductTOPallet = async (data) => {
  let response = { data: null, error: null };
  try {
    const res = await axios.post(
      `warehouse/product-location/assign-product`,
      data
    );
    response = { ...response, data: res.data };
  } catch (error) {
    response = { ...response, error: error };
  }
  return response;
};

export const editPallet = async (data, id) => {
  let response = { data: null, error: null };
  try {
    const res = await axios.put(
      `warehouse/product-location/pallet/${id}`,
      data
    );
    response = { ...response, data: res.data };
  } catch (error) {
    response = { ...response, error: error };
  }
  return response;
};

export const updateProductsDetails = async (data, id) => {
  let response = { data: null, error: null };
  try {
    const res = await axios.post(`product/update-single/${id}`, data);
    response = { ...response, data: res.data };
  } catch (error) {
    response = { ...response, error: error };
  }
  return response;
};
export const updateWarehouseProductsDetails = async (data, id) => {
  let response = { data: null, error: null };
  try {
    const res = await axios.post(
      `product/product-warehouse-update/${id}`,
      data
    );
    response = { ...response, data: res.data };
  } catch (error) {
    response = { ...response, error: error };
  }
  return response;
};

export const fetchAllGRNs = async (page, perPage, filter) => {
  let response = { data: null, error: null };
  try {
    const res = await axios.get(
      `grn?perPage=${perPage}&page=${page}&filterValue=${filter}`
    );
    response = { ...response, data: res.data };
  } catch (error) {
    response = { ...response, error: error };
  }
  return response;
};
export const fetchAllGRNsByWarehouse = async (page, perPage, filter) => {
  let response = { data: null, error: null };
  try {
    const res = await axios.get(
      `warehouse/get-warehouse-user-grns?perPage=${perPage}&page=${page}&filterValue=${filter}`
    );
    response = { ...response, data: res.data };
  } catch (error) {
    response = { ...response, error: error };
  }
  return response;
};

export const importProduct = async (formData) => {
  let response = { data: null, error: null };
  try {
    const res = await axios.post(`product/import`, formData);
    response = { ...response, data: res.data };
  } catch (error) {
    response = { ...response, error: error };
  }
  return response;
};

export const productQuantity = async (data) => {
  let response = { data: null, error: null };
  try {
    const res = await axios.post(`product/update-product-warehouse`, data);
    response = { ...response, data: res.data };
  } catch (error) {
    response = { ...response, error: error };
  }
  return response;
};
export const productSKU = async (data) => {
  let response = { data: null, error: null };
  try {
    const res = await axios.post(`product/update-sku`, data);
    response = { ...response, data: res.data };
  } catch (error) {
    response = { ...response, error: error };
  }
  return response;
};
export const searchProducts = async (query) => {
  let response = { data: null, error: null };
  try {
    const res = await axios.get(`product?filterValue=${query}`, {
      headers: {
        // Authorization: "Bearer " + token,
        Accept: "application/json",
        timeout: 1000,
      },
    });
    response = { ...response, data: res.data };
  } catch (error) {
    response = { ...response, error: error };
  }
  return response;
};
export const fetchVariationAttributes = async () => {
  let response = { data: null, error: null };
  try {
    const res = await axios.get(`product/variation/attribute`, {
      headers: {
        // Authorization: "Bearer " + token,
        Accept: "application/json",
        timeout: 1000,
      },
    });
    response = { ...response, data: res.data };
  } catch (error) {
    response = { ...response, error: error };
  }
  return response;
};
export const fetchRawProductData = async (user = null) => {
  let response = { data: null, error: null };
  try {
    const res = await axios.get(
      `product/get-raw-product-data${user ? "/" + user : ""}`,
      {
        headers: {
          Accept: "application/json",
          timeout: 1000,
        },
      }
    );
    response = { ...response, data: res.data };
  } catch (error) {
    response = { ...response, error: error };
  }
  return response;
};
export const fetchProductCategories = async () => {
  let response = { data: null, error: null };
  try {
    const res = await axios.get(`category`, {
      headers: {
        // Authorization: "Bearer " + token,
        Accept: "application/json",
        timeout: 1000,
      },
    });
    response = { ...response, data: res.data };
  } catch (error) {
    response = { ...response, error: error };
  }
  return response;
};
export const fetchProductTypes = async () => {
  let response = { data: null, error: null };
  try {
    const res = await axios.get(`get-product-types`, {
      headers: {
        // Authorization: "Bearer " + token,
        Accept: "application/json",
        timeout: 1000,
      },
    });
    response = { ...response, data: res.data };
  } catch (error) {
    response = { ...response, error: error };
  }
  return response;
};
export const fetchProductBrands = async () => {
  let response = { data: null, error: null };
  try {
    const res = await axios.get(`product/brands`, {
      headers: {
        // Authorization: "Bearer " + token,
        Accept: "application/json",
        timeout: 1000,
      },
    });
    response = { ...response, data: res.data };
  } catch (error) {
    response = { ...response, error: error };
  }
  return response;
};
export const fetchProductSuppliers = async () => {
  let response = { data: null, error: null };
  try {
    const res = await axios.get(`supplier`, {
      headers: {
        // Authorization: "Bearer " + token,
        Accept: "application/json",
        timeout: 1000,
      },
    });
    response = { ...response, data: res.data };
  } catch (error) {
    response = { ...response, error: error };
  }
  return response;
};
export const createProduct = async (data) => {
  let response = { data: null, error: null };
  try {
    const res = await axios.post(`product/store`, data);
    response = { ...response, data: res.data };
  } catch (error) {
    response = { ...response, error: error };
  }
  return response;
};
export const createWarehouseProduct = async (data, id) => {
  let response = { data: null, error: null };
  try {
    const res = await axios.post(`product/store/${id}`, data);
    response = { ...response, data: res.data };
  } catch (error) {
    response = { ...response, error: error };
  }
  return response;
};
export const updateProduct = async (data, id) => {
  let response = { data: null, error: null };
  try {
    const res = await axios.post(`product/update/${id}`, data);
    response = { ...response, data: res.data };
  } catch (error) {
    response = { ...response, error: error };
  }
  return response;
};

export const deleteProduct = async (id) => {
  let response = { data: null, error: null };
  try {
    const res = await axios.delete(`product/delete/${id}`);
    response = { ...response, data: res.data };
  } catch (error) {
    response = { ...response, error: error };
  }
  return response;
};
export const addVariantAttribute = async (data) => {
  let response = { data: null, error: null };
  try {
    const res = await axios.post(`product/variation/attribute/create`, data);
    response = { ...response, data: res.data };
  } catch (error) {
    response = { ...response, error: error };
  }
  return response;
};
export const fetchProductHistory = async (orderId, page, perPage, filter) => {
  let response = { data: null, error: null };
  try {
    const res = await axios.get(
      `product/get-history-by-product-id/${orderId}?perPage=${perPage}&page=${page}&filterValue=${filter}`
    );
    response = { ...response, data: res.data };
  } catch (error) {
    response = { ...response, error: error };
  }
  return response;
};
export const fetchProductMedia = async (productId) => {
  let response = { data: null, error: null };
  try {
    const res = await axios.get(`product/media/${productId}`);
    response = { ...response, data: res.data };
  } catch (error) {
    response = { ...response, error: error };
  }
  return response;
};

export const fetchAllPalletsLocations = async () => {
  let response = { data: null, error: null };
  try {
    const res = await axios.get(`warehouse/product-location/pallet-location`);
    response = { ...response, data: res.data };
  } catch (error) {
    response = { ...response, error: error };
  }
  return response;
};

export const addPalletsLocation = async (data) => {
  let response = { data: null, error: null };
  try {
    const res = await axios.post(
      `warehouse/product-location/pallet-location`,
      data
    );
    response = { ...response, data: res.data };
  } catch (error) {
    response = { ...response, error: error };
  }
  return response;
};
export const addProductLot = async (data) => {
  let response = { data: null, error: null };
  try {
    const res = await axios.post(`warehouse/product-lot/create`, data);
    response = { ...response, data: res.data };
  } catch (error) {
    response = { ...response, error: error };
  }
  return response;
};
export const updatePalletsLocation = async (id, data) => {
  let response = { data: null, error: null };
  try {
    const res = await axios.put(
      `warehouse/product-location/pallet-location/${id}`,
      data
    );
    response = { ...response, data: res.data };
  } catch (error) {
    response = { ...response, error: error };
  }
  return response;
};

import React, { useEffect, useRef, useState } from "react";
import Modal from "@mui/material/Modal";
import Box from "@mui/material/Box";
import { Button, TextField, IconButton } from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import { storeOrderChange } from "../../../../../services/orders.services";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import SwitchButton from "../../../../../Components/SwitchButton/SwitchButton";
import CircularLoader from "../../../../../Components/CircularLoader/CircularLoader";
import Autocomplete from "@mui/material/Autocomplete";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "50%",
  bgcolor: "background.paper",
  boxShadow: 24,
  pt: 2,
  px: 4,
  pb: 3,
};

export default function ConformModal({
  open,
  setOpen,
  setSnack,
  setOrders,
  selectedStatus,
  data,
  setData,
  orderStatus,
  setselectedStatus,
}) {
  const [attachedFiles, setAttachedFiles] = useState([]);
  const [notes, setnotes] = useState();
  const [StatusName, setStatusName] = useState();
  const [loader, setloader] = useState(false);
  const [orderDetails, setorderDetails] = useState([]);

  const handleClose = () => {
    setStatusName();
    setnotes();
    setAttachedFiles([]);
    setorderDetails([]);
    setOpen(false);
  };

  const handleCancel = () => {
    setselectedStatus(data?.fullfilment_status?.id);
    handleClose();
  };

  useEffect(() => {
    setStatusName(
      orderStatus
        .find((order) => order.id === selectedStatus)
        ?.name?.toUpperCase()
    );
  }, [selectedStatus]);

  useEffect(() => {
    setorderDetails(data?.order_details);
  }, [data?.order_details, open]);

  const handleFileChange = (e) => {
    const files = e.target.files;

    for (const file of files) {
      const reader = new FileReader();
      const lastDotIndex = file.name?.lastIndexOf(".");

      if (lastDotIndex !== -1) {
        const name = file.name?.substring(0, lastDotIndex);
        const extension = file.name?.substring(lastDotIndex);

        // Create a closure to capture the correct newFiles array
        reader.onload = (function (currentFile) {
          return function (e) {
            const newFile = {
              src: e.target.result,
              id: Math.random().toString(),
              name: name,
              extension: extension,
            };
            setAttachedFiles((prevFiles) => [...prevFiles, newFile]);
          };
        })(file);

        reader.readAsDataURL(file);
      } else {
        console.log("Invalid file name without extension");
      }
    }
  };

  const handleRemoveFile = (index) => {
    const updatedFiles = [...attachedFiles];
    updatedFiles.splice(index, 1);
    setAttachedFiles(updatedFiles);
  };

  const handleRenameFile = (index, newName) => {
    const updatedFiles = [...attachedFiles];
    updatedFiles[index].name = newName;
    setAttachedFiles(updatedFiles);
  };

  const handleSave = async () => {
    // console.log(modifiedOrderDetails);
    setloader(true);
    let response;
    try {
      const formData = new FormData();
      if (attachedFiles?.length > 0) {
        attachedFiles.forEach((image, index) => {
          const base64String = image.src.split(",")[1]; // Get the base64 string after the comma
          const blob = atob(base64String); // Decode the base64 string
          const arrayBuffer = new ArrayBuffer(blob.length);
          const uint8Array = new Uint8Array(arrayBuffer);

          for (let i = 0; i < blob.length; i++) {
            uint8Array[i] = blob.charCodeAt(i);
          }

          const fileBlob = new Blob([arrayBuffer], { type: "image/png" }); // Adjust the type accordingly

          formData.append(
            `attachments[${index}]`,
            fileBlob,
            `${image.name}${image.extension}`
          );
        });
      } else {
        formData.append(`attachments`, []);
      }
      if (StatusName === "ATTENTION") {
        const modifiedOrderDetails = orderDetails.map(
          ({ is_saleable, saleable_item_quantity, id, note }) => ({
            is_saleable,
            saleable_item_quantity,
            note,
            id,
          })
        );
        formData.append(`order_details`, JSON.stringify(modifiedOrderDetails));
      }
      if (StatusName === "FULFILLED") {
        const modifiedOrderDetails = orderDetails.map(
          ({ pallet_data, id }) => ({
            pallet_data,
            id,
          })
        );
        formData.append(`order_details`, JSON.stringify(modifiedOrderDetails));
      }

      if (data?.amount_received_by_warehouse) {
        formData.append(
          `amount_received_by_warehouse`,
          data?.amount_received_by_warehouse ?? ""
        );
      }
      formData.append(`note`, notes ?? "");
      formData.append(`order_id`, data?.id);
      formData.append(`status`, data?.status);
      formData.append(`fulfillment_order_status`, StatusName);
      response = await storeOrderChange(formData, data?.id, selectedStatus);
      if (!response.error) {
        setloader(false);
        setOrders((prevOrder) => {
          return {
            ...prevOrder,
            data: prevOrder?.data.map((order) =>
              order.id === data.id
                ? {
                    ...order,
                    order_notes: response?.data?.result[0]?.order_notes,
                    fullfilment_status: {
                      id: selectedStatus,
                      name: StatusName,
                    },
                    order_details: response?.data?.result[0]?.order_details,
                  }
                : order
            ),
          };
        });
        setData({
          ...data,
          order_notes: response?.data?.result[0]?.order_notes,
          fullfilment_status: {
            id: selectedStatus,
            name: StatusName,
          },
          order_details: response?.data?.result[0]?.order_details,
        });
        setSnack((snack) => {
          return {
            ...snack,
            open: true,
            text: response?.data?.message,
            severity: "success",
          };
        });
        handleClose();
      } else {
        setloader(false);
        setSnack((snack) => {
          return {
            ...snack,
            open: true,
            text: "Something went wrong try again later",
            severity: "error",
          };
        });
      }
    } catch (error) {
      setloader(false);
      setSnack((snack) => {
        return {
          ...snack,
          open: true,
          text: error.response.data.message,
          severity: "error",
        };
      });
    }
  };

  const handleStatusChange = async (e, index) => {
    const orderList = [...orderDetails];
    orderList[index]["is_saleable"] = e.target.checked;
    orderList[index]["saleable_item_quantity"] =
      e.target.checked === true ? orderList[index]["item_quantity"] : "0";
    setorderDetails(orderList);
  };

  const handleSaleableSelect = async (e, index) => {
    const orderList = [...orderDetails];
    orderList[index]["saleable_item_quantity"] = e.target.value;
    setorderDetails(orderList);
  };

  const handleAttentionNote = async (e, index) => {
    const orderList = [...orderDetails];
    orderList[index]["note"] = e.target.value;
    setorderDetails(orderList);
  };

  const handleAmount = async (value) => {
    setData({
      ...data,
      amount_received_by_warehouse: value,
    });
  };

  const handleLocationSelect = (selectedPalletData, index) => {
    setorderDetails((prevItems) => {
      const updatedItems = [...prevItems];
      updatedItems[index] = {
        ...updatedItems[index],
        pallet_data: selectedPalletData,
      };
      return updatedItems;
    });
  };

  return (
    <div>
      <Modal
        open={open}
        // onClose={handleClose}
        aria-labelledby="child-modal-title"
        aria-describedby="child-modal-description"
        className="inner__detail_fulfilment_modal"
      >
        <Box
          sx={{
            ...style,
            width: "75%",
            maxWidth: "1600px",
            maxHeight: "90vh",
            overflow: "auto",
          }}
        >
          <h2 id="child-modal-title" className="sale__status_title">
            Sale Status Update
          </h2>
          {(StatusName === "FULFILLED" ||
          StatusName === "CANCELLED" ||
          StatusName === "ATTENTION"
            ? true
            : false) && (
            <>
              <TextField
                label="Note"
                type="text"
                name="Note"
                value={notes}
                className="sale__status_notes"
                onChange={(e) => setnotes(e.target.value)}
                placeholder={"Note for Sale " + StatusName}
                sx={{
                  width: "100%",
                  input: { font: "menu" },
                }}
                InputLabelProps={{
                  shrink: true,
                }}
                size="small"
                multiline={true}
                rows={2}
              />
              {(data?.payment_method_title === "Cash On Delivery" ||
                data?.payment_method_title === "Cash On Pickup") &&
                StatusName === "FULFILLED" && (
                  <div style={{ marginBottom: "5px" }}>
                    <TextField
                      label="Pending Amount"
                      type="number"
                      name="amount_pending"
                      value={data?.amount_pending ?? ""}
                      className="sale__status_notes"
                      placeholder={"Amount To Be Received"}
                      sx={{
                        width: "50%",
                        input: { font: "menu" },
                      }}
                      InputLabelProps={{
                        shrink: true,
                      }}
                      size="small"
                      disabled
                    />
                    <TextField
                      label="Received Amount"
                      type="number"
                      name="amount_received_by_warehouse"
                      value={data?.amount_received_by_warehouse ?? ""}
                      className="sale__status_notes"
                      onChange={(e) => handleAmount(e.target.value)}
                      placeholder={"Enter Received Amount"}
                      sx={{
                        width: "50%",
                        input: { font: "menu" },
                      }}
                      InputLabelProps={{
                        shrink: true,
                      }}
                      size="small"
                      error={
                        data?.amount_pending !=
                        data?.amount_received_by_warehouse
                          ? true
                          : false
                      }
                    />
                  </div>
                )}
              <div className="sale_status_update_attachment_wrap">
                <input
                  type="file"
                  multiple
                  onChange={handleFileChange}
                  style={{ display: "none" }}
                  id="file-input"
                />
                <label htmlFor="file-input">
                  <Button variant="contained" component="span" color="primary">
                    Attach File
                  </Button>
                </label>
                <div className="salte_attachment_list_main_wrap">
                  {attachedFiles?.map((attachedFile, index) => (
                    <div
                      key={index}
                      className="sale_status_update_attachment_detail_box"
                    >
                      <TextField
                        variant="outlined"
                        className="attachment__field"
                        size="small"
                        value={attachedFile.name}
                        onChange={(e) =>
                          handleRenameFile(index, e.target.value)
                        }
                        InputProps={{
                          disableUnderline: true,
                        }}
                        style={{ border: "none", padding: "0" }}
                      />
                      <IconButton
                        onClick={() => handleRemoveFile(index)}
                        color="error"
                        className="icon_btn_remove_attachment"
                      >
                        <DeleteIcon />
                      </IconButton>
                    </div>
                  ))}
                </div>
              </div>
            </>
          )}
          {(StatusName === "FULFILLED" || StatusName === "ATTENTION"
            ? true
            : false) && (
            <div className="invmodal__shipment_details sales_update_inner_modal">
              <div
                className="invmodal__shipment_summaryhding"
                style={{ marginTop: "5px" }}
              >
                Product Details
              </div>
              <div className="invmodal__shdetails_content sales_update_inner_modal_table">
                <div className="invmodal__shdetails_cntleft">
                  <table className="invmshdetails_cnleft_table">
                    <thead>
                      <th className="invmodal__shdetails_tablehd content_center">
                        #
                      </th>
                      <th className="invmodal__shdetails_tablehd">Name</th>
                      <th className="invmodal__shdetails_tablehd content_center">
                        Sku
                      </th>
                      <th className="invmodal__shdetails_tablehd content_center">
                        Qty
                      </th>
                      <th className="invmodal__shdetails_tablehd">Lot/Batch</th>
                      {StatusName === "ATTENTION" && (
                        <>
                          <th className="invmodal__shdetails_tablehd">
                            Saleable
                          </th>
                          <th className="invmodal__shdetails_tablehd">
                            Saleable Qty
                          </th>
                          <th className="invmodal__shdetails_tablehd">Note</th>
                        </>
                      )}
                      {StatusName === "FULFILLED" && (
                        <>
                          <th className="invmodal__shdetails_tablehd">
                            Availability
                          </th>
                          <th className="invmodal__shdetails_tablehd">
                            Selectable Location
                          </th>
                          <th className="invmodal__shdetails_tablehd">
                            Product Quantity
                          </th>
                        </>
                      )}
                    </thead>

                    <tbody>
                      {orderDetails?.map((item, index) => {
                        return (
                          <>
                            <tr key={index}>
                              {" "}
                              <td className="invmodal__shdetails_tableitem content_center">
                                <img
                                  src={item?.products?.media?.[0]?.file || ""}
                                  className="product__table_mainimg"
                                />
                              </td>
                              <td
                                className="invmodal__shdetails_tableitem"
                                style={{ maxWidth: "250px" }}
                              >
                                {item?.products?.name ?? "-"}
                              </td>
                              <td className="invmodal__shdetails_tableitem">
                                {item?.products?.code ?? "-"}
                              </td>
                              <td className="invmodal__shdetails_tableitem">
                                {item?.item_quantity ?? "-"}
                              </td>
                              <td className="invmodal__shdetails_tableitem">
                                {item?.product_lot?.name ?? "-"}
                              </td>
                              {StatusName === "ATTENTION" && (
                                <>
                                  <td className="invmodal__shdetails_tableitem">
                                    <SwitchButton
                                      onChange={(e) =>
                                        handleStatusChange(e, index)
                                      }
                                      checked={item?.is_saleable || false}
                                    />
                                  </td>
                                  <td className="invmodal__shdetails_tableitem">
                                    <Select
                                      value={
                                        item?.saleable_item_quantity || "0"
                                      }
                                      onChange={(e) =>
                                        handleSaleableSelect(e, index)
                                      }
                                      style={{
                                        width: "80%",
                                        maxHeight: "35px",
                                        fontSize: "12px",
                                      }}
                                    >
                                      {(item?.item_quantity
                                        ? Array.from(
                                            {
                                              length: item.item_quantity + 1,
                                            },
                                            (_, index) =>
                                              item.item_quantity - index
                                          ).filter((quantity) => quantity >= 0)
                                        : []
                                      ).map((quantity, index) => (
                                        <MenuItem key={index} value={quantity}>
                                          {quantity}
                                        </MenuItem>
                                      ))}
                                    </Select>
                                  </td>
                                  <td className="invmodal__shdetails_tableitem">
                                    <TextField
                                      inputProps={{ style: { fontSize: 12 } }}
                                      placeholder="Note"
                                      InputLabelProps={{
                                        shrink: true,
                                        style: { fontSize: 12 },
                                      }}
                                      type="text"
                                      name="note"
                                      value={item?.note}
                                      onChange={(e) =>
                                        handleAttentionNote(e, index)
                                      }
                                      multiline
                                      size="small"
                                    />
                                  </td>
                                </>
                              )}
                              {StatusName === "FULFILLED" && (
                                <>
                                  <td className="invmodal__shdetails_tableitem">
                                    <table>
                                      <thead>
                                        <th
                                          style={{
                                            borderRight: "0.5px solid grey",
                                            width: "50px",
                                          }}
                                        >
                                          Unit
                                        </th>
                                        <th
                                          style={{
                                            borderRight: "0.5px solid grey",
                                            width: "70px",
                                          }}
                                        >
                                          Lot/Batch
                                        </th>
                                        <th
                                          style={{
                                            borderRight: "0.5px solid grey",
                                            width: "50px",
                                          }}
                                        >
                                          Spot
                                        </th>
                                        <th style={{ width: "50px" }}>Qty</th>
                                      </thead>
                                    </table>
                                    <tbody>
                                      {item?.products?.product_pallets?.map(
                                        (pallet, index) => {
                                          return (
                                            <>
                                              <tr
                                                style={{
                                                  borderTop: "0.5px solid grey",
                                                  width: "50px",
                                                }}
                                                key={index}
                                              >
                                                <td
                                                  style={{
                                                    borderRight:
                                                      "0.5px solid grey",
                                                    width: "50px",
                                                  }}
                                                >
                                                  {pallet?.pallet?.name ??
                                                    "N/A"}
                                                </td>
                                                <td
                                                  style={{
                                                    borderRight:
                                                      "0.5px solid grey",
                                                    width: "70px",
                                                  }}
                                                >
                                                  {pallet?.product_lot?.name ??
                                                    "N/A"}
                                                </td>
                                                <td
                                                  style={{
                                                    borderRight:
                                                      "0.5px solid grey",
                                                    width: "50px",
                                                  }}
                                                >
                                                  {pallet?.pallet?.location
                                                    ?.name ?? "N/A"}
                                                </td>
                                                <td
                                                  style={{
                                                    width: "50px",
                                                  }}
                                                >
                                                  {pallet?.quantity ?? "N/A"}
                                                </td>
                                              </tr>
                                            </>
                                          );
                                        }
                                      )}
                                    </tbody>
                                  </td>
                                  <td className="invmodal__shdetails_tableitem">
                                    <Autocomplete
                                      multiple
                                      value={
                                        item?.pallet_data?.map(
                                          (data) => data.pallet_id
                                        ) || []
                                      }
                                      onChange={(event, newValues) => {
                                        const updatedPalletData = newValues.map(
                                          (newValue) => {
                                            const existingPallet =
                                              item?.products?.product_pallets?.find(
                                                (option) =>
                                                  option.pallet.id === newValue
                                              );
                                            return {
                                              pallet_id: newValue,
                                              quantity:
                                                item?.pallet_data?.find(
                                                  (data) =>
                                                    data.pallet_id === newValue
                                                )?.quantity || 0, // keep the existing quantity or set default
                                            };
                                          }
                                        );
                                        handleLocationSelect(
                                          updatedPalletData,
                                          index
                                        );
                                      }}
                                      options={
                                        item?.products?.product_pallets?.map(
                                          (option) => option.pallet.id
                                        ) || []
                                      }
                                      getOptionLabel={(option) => {
                                        const selectedPallet =
                                          item?.products?.product_pallets?.find(
                                            (product) =>
                                              product.pallet.id === option
                                          );
                                        const palletName =
                                          selectedPallet?.pallet?.name || "";
                                        const locationName =
                                          selectedPallet?.pallet?.location
                                            ?.name || "";
                                        return palletName && locationName
                                          ? `${palletName} -> ${locationName}`
                                          : palletName || locationName;
                                      }}
                                      isOptionEqualToValue={(option, value) =>
                                        option === value
                                      }
                                      renderInput={(params) => (
                                        <TextField
                                          {...params}
                                          label="Select Pallet"
                                          variant="outlined"
                                          style={{
                                            width: "250px",
                                            fontSize: "12px",
                                          }}
                                          size="small"
                                        />
                                      )}
                                      renderOption={(props, option) => {
                                        const selectedPallet =
                                          item?.products?.product_pallets?.find(
                                            (product) =>
                                              product.pallet.id === option
                                          );
                                        return (
                                          <MenuItem
                                            {...props}
                                            key={option}
                                            value={option}
                                          >
                                            {selectedPallet?.pallet?.name}{" "}
                                            {" -> "}{" "}
                                            {
                                              selectedPallet?.pallet?.location
                                                ?.name
                                            }
                                          </MenuItem>
                                        );
                                      }}
                                    />
                                  </td>
                                  <td className="invmodal__shdetails_tableitem">
                                    <table>
                                      <thead>
                                        <th
                                          style={{
                                            borderRight: "0.5px solid grey",
                                            width: "50px",
                                          }}
                                        >
                                          Unit
                                        </th>
                                        <th
                                          style={{
                                            width: "80px",
                                          }}
                                        >
                                          Quantity
                                        </th>
                                      </thead>
                                      <tbody>
                                        {item?.pallet_data?.map((data, i) => {
                                          const selectedPallet =
                                            item?.products?.product_pallets?.find(
                                              (product) =>
                                                product.pallet.id ===
                                                data.pallet_id
                                            );
                                          const palletName =
                                            selectedPallet?.pallet?.name || "";
                                          const locationName =
                                            selectedPallet?.pallet?.location
                                              ?.name || "";
                                          const isQuantityExceeded =
                                            parseInt(data.quantity) >
                                            parseInt(selectedPallet?.quantity);
                                          return (
                                            <>
                                              <tr
                                                style={{
                                                  borderTop: "0.5px solid grey",
                                                }}
                                                key={i}
                                              >
                                                <td
                                                  style={{
                                                    borderRight:
                                                      "0.5px solid grey",
                                                    width: "50px",
                                                  }}
                                                >
                                                  {palletName}
                                                </td>
                                                <td
                                                  style={{
                                                    width: "80px",
                                                  }}
                                                >
                                                  <TextField
                                                    type="number"
                                                    value={data.quantity}
                                                    onChange={(e) => {
                                                      const updatedPalletData =
                                                        [...item.pallet_data];
                                                      updatedPalletData[
                                                        i
                                                      ].quantity =
                                                        e.target.value;
                                                      handleLocationSelect(
                                                        updatedPalletData,
                                                        index
                                                      );
                                                    }}
                                                    variant="outlined"
                                                    size="small"
                                                    error={isQuantityExceeded}
                                                    style={{
                                                      width: "100%",
                                                    }}
                                                  />
                                                </td>
                                              </tr>
                                            </>
                                          );
                                        })}
                                      </tbody>
                                    </table>
                                  </td>
                                </>
                              )}
                            </tr>
                          </>
                        );
                      })}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          )}
          <p id="child-modal-description">
            {" "}
            Do you want to Change the Order status from{" "}
            <strong>
              {data?.fullfilment_status?.name?.toUpperCase() ?? "-"}
            </strong>{" "}
            to <strong>{StatusName}.</strong>
            {StatusName === "CANCELLED" && (
              <>
                <br />
                <strong style={{ color: "red" }}>
                  Note: Canceling is an irreversible action!
                </strong>
              </>
            )}
            {data?.shipment?.shipment_status === "Cancelled" && (
              <>
                <br />
                <strong style={{ color: "red" }}>
                  Note: Shipment for this Order is Cancelled!
                </strong>
              </>
            )}
            {(data?.payment_method_title === "Cash On Delivery" ||
              data?.payment_method_title === "Cash On Pickup") &&
              StatusName === "FULFILLED" && (
                <>
                  <br />
                  <strong style={{ color: "red" }}>
                    Note: Receive the Pending Amount!
                  </strong>
                </>
              )}
          </p>
          <div
            style={{ display: "flex", justifyContent: "end" }}
            className="inner__detail_fulfilment_btn"
          >
            <Button onClick={handleSave} variant="contained" size="small">
              {loader ? <CircularLoader /> : "Confirm"}
            </Button>
            <Button
              onClick={handleCancel}
              variant="contained"
              color="error"
              size="small"
              style={{ marginLeft: "5px" }}
            >
              Decline
            </Button>
          </div>
        </Box>
      </Modal>
    </div>
  );
}

import React, { useState } from "react";
// components imports

// apis imports
import { fetchShipmentDetailsHistoryById } from "../../services/ShipperServices/Shipments/shipments.services";
// library imports
import Backdrop from "@mui/material/Backdrop";
import Modal from "@mui/material/Modal";
import HistoryTable from "../BoltTable/BoltTable";

export default function ShipmentHistoryModal({ open, setOpen, setSnack }) {
  const [data, setData] = useState({});
  const handleClose = () => {
    setOpen(false);
    setData({});
  };

  const columns = [
    {
      id: 0,
      label: "#",
      render: (row) => <>{row?.id ?? "-"}</>,
      width: "130px",
    },
    {
      id: 1,
      label: "Parcel Status",
      field_name: "ShipmentStatus",
      render: (row) => <>{row?.ShipmentStatus ?? "-"}</>,
      width: "200px",
      fixed: "left",
    },
    {
      id: 2,
      label: "Status Date",
      field_name: "StatusDate",
      render: (row) => <>{row.StatusDate?.slice(0, 10) ?? "-"}</>,
      width: "200px",
    },
    {
      id: 3,
      label: "Status Time",
      field_name: "StatusDate",
      render: (row) => <>{row.StatusDate?.slice(11, 16) ?? "-"}</>,
      width: "200px",
    },
  ];

  const getShipmentHistory = async (page, perPage, filter) => {
    let response = await fetchShipmentDetailsHistoryById(open);
    if (!response.error) {
      let newData = {
        current_page: 1,
        data: response?.data?.result,
        first_page_url: "",
        from: 1,
        last_page: 1,
        last_page_url: "",
        links: [],
        next_page_url: null,
        path: "",
        per_page: response?.data?.result.length,
        prev_page_url: null,
        to: response?.data?.result.length,
        total: response?.data?.result.length,
      };
      if (response?.data?.result.length > 0) {
        setData(newData);
      } else {
        setData({});
      }
    } else {
      setSnack((snack) => {
        return {
          ...snack,
          open: true,
          text: "Something went wrong",
          severity: "error",
        };
      });
    }
  };

  return (
    <>
      <div>
        <Modal
          aria-labelledby="transition-modal-title"
          aria-describedby="transition-modal-description"
          open={open}
          onClose={handleClose}
          closeAfterTransition
          BackdropComponent={Backdrop}
          BackdropProps={{
            timeout: 500,
          }}
        >
          <div className="prdtable__hist_modal">
            <div
              className="prdtable__hist_modalheading history__product_heading  history__main_heading"
              style={{
                border: "none",
              }}
            >
              Parcel's History.
            </div>
            <div className="prdtable__hist_modalcontent history__product_content">
              <HistoryTable
                message={"No Parcel History Available To Display!"}
                searchPlaceHolder={"Search Not Available"}
                getAllData={getShipmentHistory}
                data={data}
                columns={columns}
              />
            </div>
            <div className=" history__product_footer">
              <div
                className="history__product_footer_btn"
                onClick={handleClose}
              >
                x
              </div>
            </div>
          </div>
        </Modal>
      </div>
    </>
  );
}

import React, { useState } from "react";
import LocationsTable from "../../../Components/BoltTable/BoltTable";
import {
  deleteStorageLocations,
  getStorageLocations,
} from "../../../services/WarehouseServices/storageMap.services";
import Tooltip from "../../../Components/Tooltip/Tooltip";
import { FaRegEdit } from "react-icons/fa";
import { MdOutlineCancel } from "react-icons/md";
import { FcPlus } from "react-icons/fc";
import LocationModal from "./Components/LocationModal";
import { AiOutlineBarcode } from "react-icons/ai";
import LabelModal from "../../Products/Components/LabelModal/LabelModal";
import {
  TotalSpaceColumn,
  Dimensions,
  Weight,
} from "../../Products/Product/ProductService";
import Confirm from "../../../Components/Popups/Confirm";

const Locations = ({ setSnack }) => {
  // new states
  const [locations, setLocations] = useState([]);
  const [editLocationModal, setEditLocationModal] = useState();
  const [addLocationModal, setAddLocationModal] = useState();
  const [labelModal, setLabelModal] = useState(false);
  const [confirmBox, setConfirmBox] = useState();
  const [deleting, setDeleting] = useState(false);

  const columns = [
    {
      id: 0,
      label: "#",
      render: (row) => <>{row?.id ?? "-"}</>,
      width: "200px",
    },
    {
      id: 1,
      label: "Name",
      field_name: "name",
      render: (row) => <>{row?.name ?? "-"}</>,
      fixed: "left",
      width: "200px",
    },
    {
      id: 2,
      label: "Code",
      render: (row) => <>{row?.code ?? "-"}</>,
      width: "200px",
    },
    {
      id: 3,
      label: "Dimensions (L*W*H)",
      render: (row) => (
        <>
          <Dimensions
            length={row?.length ?? 0}
            width={row?.width ?? 0}
            height={row?.height ?? 0}
            unit={row?.unit === "imperial" ? "inch" : "cm"}
            space={true}
          />
        </>
      ),
      width: "200px",
    },
    {
      id: 4,
      label: "Weight Capacity",
      field_name: "weight_capacity",
      render: (row) => (
        <>
          <Weight
            weight={row?.weight_capacity ?? 0}
            unit={row?.unit === "imperial" ? "lb" : "kg"}
            space={true}
          />
        </>
      ),
      width: "200px",
    },
    {
      id: 5,
      label: "Volume Capacity",
      field_name: "total-volume",
      render: (row) => (
        <>
          <TotalSpaceColumn
            length={row?.length ?? 0}
            width={row?.width ?? 0}
            height={row?.height ?? 0}
            unit={row?.unit === "imperial" ? "inch" : "cm"}
            quantity={1}
            space={true}
          />
        </>
      ),
      width: "200px",
    },
    {
      id: 6,
      label: "Cost",
      field_name: "cost",
      render: (row) => <>{row?.cost ? `${row?.cost}$` : "0$"}</>,
      width: "200px",
    },
    {
      id: 7,
      label: "Color",
      field_name: "color",
      render: (row) => (
        <div className="flex items-center">
          {row?.color ? (
            <div
              style={{ backgroundColor: row?.color }}
              className={`mr-1 w-[16px] h-[16px] border border-solid border-grey-500`}
            ></div>
          ) : null}
        </div>
      ),
      width: "200px",
    },
    {
      id: 8,
      label: "Created At",
      render: (row) => <>{row?.created_at?.slice(0, 10) ?? "-"}</>,
      width: "200px",
    },
    {
      id: 9,
      label: "Actions",
      render: (row, rowIndex) => (
        <div className="table_action__wrap">
          <Tooltip title="Update Location">
            <div
              className="table_action__btn"
              onClick={() => setEditLocationModal(row)}
            >
              <FaRegEdit size={10} />
              Edit
            </div>
          </Tooltip>

          <Tooltip title="Delete Location">
            <div
              className="table_action__btn"
              onClick={() => setConfirmBox(row?.id)}
            >
              <MdOutlineCancel size={10} />
              Delete
            </div>
          </Tooltip>
          {row?.code ? (
            <Tooltip title="Print Barcode">
              <div
                className="table_action__btn"
                onClick={() => setLabelModal({ row, rowIndex })}
              >
                <AiOutlineBarcode size={10} />
                Barcode
              </div>
            </Tooltip>
          ) : null}
        </div>
      ),
      width: "200px",
      fixed: "right",
    },
  ];

  const getAllLocations = async (page, perPage, filter, nestedSearch) => {
    let response;
    response = await getStorageLocations(page, perPage, filter, nestedSearch);
    if (!response.error) {
      setLocations(response?.data?.result);
    }
  };
  const handleDeleteLocation = async (id) => {
    setDeleting(true);
    let response;
    response = await deleteStorageLocations(id);
    setDeleting(false);
    if (!response.error) {
      setLocations((prevLocations) => {
        return {
          ...prevLocations,
          data: prevLocations.data?.filter((loc) => loc.id !== id),
        };
      });
      setConfirmBox(false);
    }
  };

  return (
    <>
      <Confirm
        open={confirmBox}
        setOpen={setConfirmBox}
        handleConfirm={() => {
          handleDeleteLocation(confirmBox);
        }}
        loader={deleting}
      />
      <LocationModal
        setSnack={setSnack}
        open={editLocationModal}
        setOpen={setEditLocationModal}
        type="edit"
        previousData={{
          name: editLocationModal?.name,
          unit: editLocationModal?.unit ?? "imperial",
          length: editLocationModal?.length,
          width: editLocationModal?.width,
          height: editLocationModal?.height,
          weight_capacity: editLocationModal?.weight_capacity,
          cost: editLocationModal?.cost,
          color: editLocationModal?.color ?? "white",
          is_use_able: ["true", true, "1"].includes(
            editLocationModal?.is_use_able
          ),
        }}
        locations={locations}
        setLocations={setLocations}
      />
      <LocationModal
        setSnack={setSnack}
        open={addLocationModal}
        setOpen={setAddLocationModal}
        type="add"
        locations={locations}
        setLocations={setLocations}
      />
      <LabelModal
        open={labelModal}
        setOpen={setLabelModal}
        setSnack={setSnack}
        labelData={{
          code: labelModal?.row?.code,
          name: labelModal?.row?.name,
          image: labelModal?.row?.media?.find((image) => image?.is_primary)
            ?.file,
        }}
        title="Pallet's Barcode"
        subtitle={`${labelModal?.row?.name ?? ""}`}
      />
      <div className="warehouse_orders_header">
        <div>
          <span className="warehouse_orders_hdrheading">Locations List</span>
        </div>
        <div
          className="products__page_headerrt"
          onClick={() => setAddLocationModal(true)}
        >
          <div className="products__page_hdraddprod">
            <FcPlus size={10} />
            <span>ADD LOCATION</span>
          </div>
        </div>
      </div>
      <div className="custom__table_size1 actionbtn__needtobe_fixwidth">
        <LocationsTable
          message="No Locations Available to display!"
          searchPlaceHolder="Search Locations"
          columns={columns}
          data={locations}
          getAllData={getAllLocations}
        />
      </div>
    </>
  );
};

export default Locations;

import React, { useRef, useState } from "react";
import Checkbox from "@mui/material/Checkbox";
import Pagination from "@mui/material/Pagination";
import { MdViewColumn, MdOutlineFilterAlt, MdFilterAlt } from "react-icons/md";
import DropdownIcon from "../../assets/icons/dispatcher-dropdown.svg";
import SearchIcon from "../../assets/icons/dispatcher-search.svg";
import { useEffect } from "react";

import "./BoltTable.css";
import ColumnsModal from "../Modals/ColumnsModal/ColumnsModal";
import CarrierTableLoader from "../CarrierTableLoader/CarrierTableLoader";
import Sad from "@mui/icons-material/SentimentVeryDissatisfied";
import CircularLoader from "../CircularLoader/CircularLoader";
import { CiFilter } from "react-icons/ci";

import Tooltip from "../Tooltip/Tooltip";
import { useSelector } from "react-redux";
import { findSettingByType } from "../../helpers/helpers";
import Popper from "@mui/material/Popper";
import TableSkeletonLoader from "../Common/TableSkeleton";
import useAutoAdjustTableHeight from "../../hooks/useAutoAdjustableHeight";

const BoltTable = ({
  columns,
  data,
  getAllData,
  selectedRows,
  setSelectedRows,
  detailsComponent,
  expandable = false,
  selectable = false,
  showVariants = false,
  footer = null,
  refresh,
  message = "No Data to display!",
  searchPlaceHolder = "Search Record",
  handleRowClick,
  isRowClick = null,
  disableAutoResizing,
}) => {
  const [paginationData, setpaginationData] = useState(data);
  const [editableData, setEditableData] = useState(data?.data);
  const [page, setPage] = useState(paginationData?.current_page || 1);
  const [modal, setModal] = useState(false);
  const [expandedRow, setExpandedRow] = useState();
  const [isDragging, setIsDragging] = useState(false);
  const [startX, setStartX] = useState(null);
  const [scrollLeft, setScrollLeft] = useState(0);
  const bottomRef = useRef(null);
  const handleFieldChange = (rowIndex, field, value) => {
    const updatedData = [...editableData];
    updatedData[rowIndex][field] = value;
    setEditableData(updatedData);
  };

  const [filteredCols, setFilteredCols] = useState();
  const [nestedSearch, setnestedSearch] = useState([]);
  const [nestedSearchFinal, setnestedSearchFinal] = useState([]);
  const [cols, setCols] = useState();
  const [filter, setFilter] = useState("");
  const [loader, setloader] = useState("main");
  const user = useSelector((state) => state.user);
  const [popperId, setPopperId] = useState(null);
  const [anchorEl, setAnchorEl] = useState(null);
  const [filterValues, setFilterValues] = useState({});
  const textFieldRef = useRef(null);
  const { tableHeight, tableRef } = useAutoAdjustTableHeight(bottomRef);

  const handlePopper = (event, id) => {
    const currentAnchorEl =
      anchorEl === event.currentTarget ? null : event.currentTarget;

    setPopperId(currentAnchorEl ? id : null);
    setAnchorEl(currentAnchorEl);

    if (currentAnchorEl) {
      // Focus the input when the popper opens
      setTimeout(() => {
        if (textFieldRef.current) {
          textFieldRef.current.focus();
        }
      }, 0);
    }
  };

  const handleColSearch = (e) => {
    let filtered = cols.filter((item) =>
      item.label.toLowerCase().includes(e.target.value.toLowerCase())
    );
    setFilteredCols(filtered);
  };
  const handleColChecked = (id, checked) => {
    setFilteredCols((prevCols) =>
      prevCols.map((col) => (col.id === id ? { ...col, checked } : col))
    );
  };
  const handleShowAllCols = (show) => {
    setFilteredCols((prevCols) => {
      return prevCols.map((col) => {
        return { ...col, checked: show };
      });
    });
  };

  const handleMouseDown = (event) => {
    setIsDragging(true);
    setStartX(event.pageX - tableRef.current.offsetLeft);
    setScrollLeft(tableRef.current.scrollLeft);
  };

  const handleMouseMove = (event) => {
    if (!isDragging) return;
    const x = event.pageX - tableRef.current.offsetLeft;
    const distance = x - startX;
    tableRef.current.scrollLeft = scrollLeft - distance;
  };

  const handleMouseUp = () => {
    setIsDragging(false);
  };
  const handleKeyPress = (event, row, rowIndex) => {
    // console.log(event);
    if (event.key === "Enter") {
      event.preventDefault();
      // console.log(row, rowIndex);
    }
  };
  const handleRowSelect = (id) => {
    // console.log(id);
    if (id === "all") {
      const allIdsSelected = editableData.every((item) =>
        selectedRows.includes(item.id)
      );
      if (allIdsSelected) {
        setSelectedRows([]);
      } else {
        const newSelectedRows = editableData.map((item) => item.id);
        setSelectedRows(newSelectedRows);
      }
    } else {
      if (selectedRows.includes(id)) {
        const newSelectedRows = selectedRows.filter((rowId) => rowId !== id);
        setSelectedRows(newSelectedRows);
      } else {
        setSelectedRows([...selectedRows, id]);
      }
    }
  };
  const hideModal = () => {
    setModal(false);
  };

  const handlePerPage = (Perpage) => {
    // table is loader type
    fetchData(1, Perpage, "table");
    // getAllData(1, Perpage, filter);
  };

  const handlePage = (event, value) => {
    setPage(value);
    // table is loader type
    fetchData(value, paginationData?.per_page, "table");
    // getAllData(value, paginationData?.per_page, filter);
  };

  const [debounceTimer, setDebounceTimer] = useState(null); // State to hold debounce timer ID

  const handleNestedFilter = (data, column) => {
    // Update nestedSearch immediately
    const foundItemIndex = nestedSearch.findIndex(
      (item) => item.name === column
    );

    if (foundItemIndex !== -1) {
      const updatedNestedSearch = [...nestedSearch];
      updatedNestedSearch[foundItemIndex] = {
        ...updatedNestedSearch[foundItemIndex],
        filter: data,
      };
      setnestedSearch(updatedNestedSearch); // Use functional update to ensure latest state
    } else {
      const newX = {
        name: column,
        filter: data,
      };
      const newNestedSearch = [...nestedSearch, newX];
      setnestedSearch(newNestedSearch); // Use functional update to ensure latest state
      // console.error("Column not found in nestedSearch:", column);
    }
  };

  useEffect(() => {
    if (nestedSearch?.length > 0) {
      if (debounceTimer) {
        // Start debounce timer
        clearTimeout(debounceTimer); // Clear existing timer
      }
      const newTimer = setTimeout(() => {
        setnestedSearchFinal(nestedSearch); // Update nestedSearchFinal after debounce
        // console.log("Debounce working. Final Search:", nestedSearch);
      }, 300);
      setDebounceTimer(newTimer); // Save the new timer ID
    }
  }, [nestedSearch]);

  const getColumnFilter = (columnName) => {
    const foundItem = nestedSearch.find((item) => item.name === columnName);
    return foundItem ? foundItem.filter : "";
  };

  useEffect(() => {
    setEditableData(data?.data);
    setpaginationData(data);
    setCols(columns);
  }, [data, columns]);

  useEffect(() => {
    setFilteredCols(cols);
  }, [cols]);

  const fetchData = async (page, perPage, loaderType) => {
    const perPageSetting = findSettingByType(user?.general_settings, "perPage");
    const recordPerPage = perPage || perPageSetting?.value || 20;
    setloader(
      loaderType || (filter || nestedSearchFinal.length > 0 ? "search" : "main")
    );
    let nestedSearch = nestedSearchFinal
      .filter((filter) => filter.filter !== "")
      .map((filter) => `&${filter.name}=${filter.filter}`)
      .join("");
    await getAllData(page ?? 1, recordPerPage, filter, nestedSearch);
    setloader(false);
  };

  useEffect(() => {
    fetchData();
  }, [filter, refresh, nestedSearchFinal]);

  useEffect(() => {
    setPage(paginationData?.current_page || 1);
    // console.log(paginationData?.current_page);
  }, [paginationData]);

  return (
    <>
      {loader === "main" ? (
        <TableSkeletonLoader />
      ) : (
        <div
          className="bolt__table_main_wrap"
          // ref={tableRef}
        >
          {(filter != "" ||
            nestedSearch.length > 0 ||
            editableData?.length > 0) && (
            <div className="editable__table_toolbar">
              {" "}
              <div className="editable__table_toolbarlft">
                <div className="editabletab__searchbar_wrap">
                  <input
                    type="text"
                    className="editabletab__searchbar_field"
                    placeholder={searchPlaceHolder}
                    value={filter}
                    onChange={(e) => setFilter(e.target.value)}
                  />
                  {loader === "search" ? (
                    <CircularLoader />
                  ) : (
                    // <FcSearch className="editabletab__searchbar_icon" size={20} />
                    <img
                      src={SearchIcon}
                      className="editabletab__searchbartabel_icon"
                      // className="dispatcher__screen_searchicon"
                      alt=""
                    />
                  )}
                </div>
                <div className="editable__tabtbarlft_tab">
                  <MdOutlineFilterAlt size={18} />
                  <span>FILTERS</span>
                  <div className="editable__tabtbarlft_tabbadge">{0}</div>
                </div>
                <div
                  className="editable__tabtbarlft_tab"
                  onMouseEnter={() => setModal("columns")}
                  onMouseLeave={hideModal}
                >
                  <MdViewColumn size={18} />
                  <span>COLUMNS</span>
                  <div className="editable__tabtbarlft_tabbadge">
                    {filteredCols?.filter((col) => col?.checked !== false)
                      ?.length ?? 0}
                  </div>
                  {modal === "columns" && (
                    <ColumnsModal
                      handleColSearch={handleColSearch}
                      filteredCols={filteredCols}
                      handleColChecked={handleColChecked}
                      handleShowAllCols={handleShowAllCols}
                      onClose={() => setModal(false)}
                      style={{ top: "25px" }}
                    />
                  )}
                </div>
              </div>
              <div className="editable__table_toolbarrt">
                <span>
                  <span>Records Per Page: </span>
                  <select
                    onChange={(e) => handlePerPage(e.target.value)}
                    value={paginationData?.per_page}
                    className="editable__tabrows_perpage"
                    sx={{
                      maxHeight: "30px",
                      "& *": {
                        maxHeight: "30px",
                        fontSize: "14px",
                      },
                    }}
                  >
                    <option value={10}>10</option>
                    <option value={20}>20</option>
                    <option value={40}>40</option>
                    <option value={60}>60</option>
                    <option value={80}>80</option>
                    <option value={100}>100</option>
                    <option value={120}>120</option>
                    <option value={140}>140</option>
                    <option value={160}>160</option>
                    <option value={180}>180</option>
                    <option value={200}>200</option>
                  </select>
                </span>
                <span>( Total: {paginationData?.total || 0} )</span>
              </div>
            </div>
          )}
          <div
            className="editable__table_container"
            ref={tableRef}
            onMouseDown={handleMouseDown}
            onMouseMove={handleMouseMove}
            onMouseUp={handleMouseUp}
            style={{ height: disableAutoResizing ? "" : `${tableHeight}px` }}
          >
            {loader === "table" ? (
              // <div className="driver__screen_loading">
              //   <CarrierTableLoader />
              // </div>
              <TableSkeletonLoader />
            ) : (
              <>
                <table className="editable__table">
                  <thead>
                    <tr>
                      {expandable && (
                        <th>
                          <img
                            src={DropdownIcon}
                            alt=""
                            className="bolttabscreen__theader_hdrophide"
                          />
                        </th>
                      )}
                      {selectable && (
                        <th>
                          <Checkbox
                            className="bolt__table_checkbox"
                            size="small"
                            checked={
                              selectedRows?.length === editableData?.length
                            }
                            onChange={() => handleRowSelect("all")}
                          />
                        </th>
                      )}
                      {showVariants && <th>Variant</th>}
                      {filteredCols?.map((column, index) =>
                        column?.checked !== false ? (
                          <>
                            <th
                              key={index}
                              style={{
                                maxWidth: column.width,
                                backgroundColor: column.headerColor ?? "",
                                color: column.headerTextColor ?? "grey",
                              }}
                              className={`${
                                column?.fixed === "left"
                                  ? "tablecol__fixed_left"
                                  : column?.fixed === "right"
                                  ? "tablecol__fixed_right"
                                  : ""
                              }`}
                            >
                              <div
                                style={{
                                  display: column.searchable ? "flex" : "",
                                }}
                                className="w-[100%] relative items-center justify-between"
                                onClick={(e) =>
                                  column.searchable
                                    ? handlePopper(e, column.field_name)
                                    : null
                                }
                              >
                                {column.label}{" "}
                                {column.searchable && (
                                  <>
                                    {filterValues[column?.field_name] ? (
                                      <MdFilterAlt
                                        style={{
                                          color: "rgb(25, 118, 210)",
                                        }}
                                      />
                                    ) : (
                                      <CiFilter />
                                    )}
                                  </>
                                )}
                              </div>
                              {column.searchable && (
                                <Popper
                                  id={popperId}
                                  open={popperId === column.field_name}
                                  anchorEl={anchorEl}
                                  placement="top"
                                  className="bg-white rounded-[2px] p-2 mb-2 shadow-[rgba(0,0,0,0.1)_0px_1px_4px] border border-gray-300 z-[1000]"
                                >
                                  <input
                                    id={column.field_name}
                                    type="text"
                                    placeholder={`Search by ${column.label}`}
                                    value={
                                      filterValues[column.field_name] || ""
                                    }
                                    ref={textFieldRef}
                                    onChange={(e) => {
                                      const newValue = e.target.value;
                                      setFilterValues((prev) => ({
                                        ...prev,
                                        [column.field_name]: newValue,
                                      }));
                                      handleNestedFilter(
                                        newValue,
                                        column.field_name
                                      );
                                    }}
                                    className="w-[150px] h-[25px] text-xs border border-gray-300 rounded-[2px] p-1 focus:outline-none"
                                  />
                                </Popper>
                              )}
                            </th>
                          </>
                        ) : null
                      )}
                    </tr>
                  </thead>
                  {editableData?.length > 0 ? (
                    <tbody>
                      {editableData.map((row, rowIndex) => (
                        <>
                          <Tooltip title={isRowClick ?? ""}>
                            <tr
                              key={row.id}
                              onClick={
                                isRowClick ? () => handleRowClick(row) : null
                              }
                            >
                              {expandable && (
                                <td>
                                  <img
                                    src={DropdownIcon}
                                    alt=""
                                    className={`bolttabscreen__trow_hdrop ${
                                      expandedRow === rowIndex
                                        ? "bolttabscreen__trow_hdropactive"
                                        : ""
                                    }`}
                                    onClick={() =>
                                      setExpandedRow(
                                        expandedRow === rowIndex ? "" : rowIndex
                                      )
                                    }
                                  />
                                </td>
                              )}
                              {selectable && (
                                <td>
                                  <Checkbox
                                    className="bolt__table_checkbox"
                                    size="small"
                                    checked={selectedRows?.includes(row?.id)}
                                    onChange={() => handleRowSelect(row?.id)}
                                  />
                                </td>
                              )}
                              {showVariants && <th>Main</th>}
                              {/* <td>{rowIndex + 1}</td> */}
                              {filteredCols?.map((column, index) =>
                                column?.checked !== false ? (
                                  <td
                                    key={index}
                                    className={`${
                                      column?.fixed === "left"
                                        ? "tablecol__fixed_left"
                                        : column?.fixed === "right"
                                        ? "tablecol__fixed_right"
                                        : ""
                                    }`}
                                    style={{
                                      maxWidth: column.width,
                                      cursor: isRowClick ? "pointer" : "",
                                    }}
                                  >
                                    {column.render ? (
                                      <div
                                        className={`bolt__tablemax_fixed_height ${
                                          column?.disabled &&
                                          column?.disabled(row)
                                            ? "bolt_table_cancle_td"
                                            : ""
                                        } `}
                                      >
                                        {column.render(
                                          row,
                                          rowIndex,
                                          handleFieldChange
                                        )}
                                      </div>
                                    ) : column.editable ? (
                                      <input
                                        type={column.field_type ?? "text"}
                                        value={row[column.field_name]}
                                        style={{ width: column.width }}
                                        onChange={(e) =>
                                          handleFieldChange(
                                            rowIndex,
                                            column.field_name,
                                            e.target.value
                                          )
                                        }
                                        onKeyPress={(event) => {
                                          handleKeyPress(event, row, rowIndex);
                                        }}
                                        className="editble__table_input"
                                      />
                                    ) : (
                                      <div>{row[column.field_name]}</div>
                                    )}
                                  </td>
                                ) : null
                              )}
                            </tr>
                          </Tooltip>

                          <tr
                            style={{ width: "100%" }}
                            className={`bolt__table_expandedrow ${
                              expandedRow === rowIndex
                                ? "bolt__table_expandedrowactive"
                                : ""
                            }`}
                          >
                            {/* <td colSpan={56}>
                          <div
                            className={`bolt__table_expandedbox ${
                              expandedRow === rowIndex
                                ? "bolt__table_expandedboxactive"
                                : ""
                            }`}
                          >
                            <div className="btable__expandedbox_content">
                              {detailsComponent && detailsComponent(row)}
                            </div>
                          </div>
                        </td> */}
                          </tr>

                          {row?.children &&
                            showVariants &&
                            row?.children?.map((varient, varientIndex) => (
                              <>
                                <tr
                                  key={varient.id}
                                  className="bolttabscreen__varrow"
                                >
                                  {expandable && (
                                    <td>
                                      <img
                                        src={DropdownIcon}
                                        alt=""
                                        className={`bolttabscreen__trow_hdrop ${
                                          expandedRow === rowIndex
                                            ? "bolttabscreen__trow_hdropactive"
                                            : ""
                                        }`}
                                        onClick={() =>
                                          setExpandedRow(
                                            expandedRow === rowIndex
                                              ? ""
                                              : rowIndex
                                          )
                                        }
                                      />
                                    </td>
                                  )}
                                  {selectable && (
                                    <td>
                                      <Checkbox
                                        className="bolt__table_checkbox"
                                        checked={selectedRows?.includes(
                                          parseInt(varient.parent_id)
                                        )}
                                        size="small"
                                        // sx={{
                                        //   "& .MuiSvgIcon-root": { fontSize: 8 },
                                        // }}
                                        onChange={() =>
                                          handleRowSelect(varient?.id)
                                        }
                                        disabled
                                      />
                                    </td>
                                  )}
                                  <td>{varientIndex + 1}</td>
                                  {filteredCols?.map((column, index) =>
                                    column?.checked !== false ? (
                                      <td
                                        key={index}
                                        className={`${
                                          column?.fixed === "left"
                                            ? "tablecol__fixed_left"
                                            : column?.fixed === "right"
                                            ? "tablecol__fixed_right"
                                            : ""
                                        }`}
                                        style={{ maxWidth: column.width }}
                                      >
                                        {column.render ? (
                                          <div
                                            className={`bolt__tablemax_fixed_height ${
                                              column?.disabled &&
                                              column?.disabled(row)
                                                ? "bolt_table_cancle_td"
                                                : ""
                                            } `}
                                          >
                                            {column.render(
                                              varient,
                                              rowIndex,
                                              handleFieldChange
                                            )}
                                          </div>
                                        ) : column.editable ? (
                                          <input
                                            type={column.field_type ?? "text"}
                                            value={varient[column.field_name]}
                                            style={{ width: column.width }}
                                            onChange={(e) =>
                                              handleFieldChange(
                                                varientIndex,
                                                column.field_name,
                                                e.target.value
                                              )
                                            }
                                            onKeyPress={(event) => {
                                              handleKeyPress(
                                                event,
                                                varient,
                                                varientIndex
                                              );
                                            }}
                                            className="editble__table_input"
                                          />
                                        ) : (
                                          <div>
                                            {varient[column.field_name]}
                                          </div>
                                        )}
                                      </td>
                                    ) : null
                                  )}
                                </tr>
                              </>
                            ))}
                        </>
                      ))}
                      {footer
                        ? React.cloneElement(footer, {
                            className: `${
                              footer.props.className || ""
                            } sticky bottom-0 bg-white pt-1`,
                            style: { ...footer.props.style, zIndex: 10 },
                          })
                        : null}
                    </tbody>
                  ) : null}
                </table>
                {(!editableData || editableData.length === 0) && (
                  <div className="driver__screen_empty">
                    <div className="driver__scrnempt_content">
                      <Sad className="driver__scrnempt_contenticon" />
                      <div>{message}</div>
                    </div>
                  </div>
                )}
              </>
            )}
          </div>
          {(filter != "" || editableData?.length > 0) && (
            <div className="footer__boltTable_pagination" ref={bottomRef}>
              <Pagination
                count={paginationData?.last_page}
                onChange={handlePage}
                defaultPage={page}
                currentPage={page}
                size="small"
                showFirstButton
                showLastButton
              />
            </div>
          )}
        </div>
      )}
    </>
  );
};

export default BoltTable;
